import { initializeApp } from "firebase/app";
import { getAnalytics, } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging"

const bodyy = document.querySelector("body")

const p = document.createElement("p")



if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js', {
        scope: '/'
    }).then((registration) => {
        console.log('ServiceWorker registration successful with scope:', registration.scope);
        p.textContent = registration
        bodyy.appendChild(p)

    }).catch((error) => {
        console.error('ServiceWorker registration failed:', error);
        p.textContent = error
        bodyy.appendChild(p)


    });
}


const firebaseConfig = {
    apiKey: "AIzaSyCceemzRZ4n4xsfGimkQhDcdrB1g_3ImnI",
    authDomain: "testmessage-6a1cc.firebaseapp.com",
    projectId: "testmessage-6a1cc",
    storageBucket: "testmessage-6a1cc.appspot.com",
    messagingSenderId: "419179866856",
    appId: "1:419179866856:web:05b88d4fde1cc1dfd0375d",
    measurementId: "G-J9T97VE4W0"
};



// function requestPermission() {
//     console.log('Requesting permission...');
//     Notification.requestPermission().then((permission) => {
//         if (permission === 'granted') {
//             console.log('Notification permission granted.');
//             const app = initializeApp(firebaseConfig);
//             const messaging = getMessaging(app)
//             getToken(messaging, { vapidKey: 'BGAuvcT6diuWRat0o0VtCoC-5XsrmGWOFoU6l42oiwgnHs7Agd37ieQ0l_8iQBtO57ekXQNSNkhA_1RZQDec-5o' }).then((currentToken) => {
//                 if (currentToken) {
//                     console.log('CurrentToken', currentToken)
//                     const bodyy = document.querySelector("body")
//                     const p = document.createElement("p")

//                     p.textContent = `${currentToken}`
//                     bodyy.appendChild(p)
//                 } else {
//                     console.log("cant get the token")
//                 }
//             })
//         } else {
//             console.log("do not have permission")
//         }
//     })
// }


function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            const app = initializeApp(firebaseConfig);
            const messaging = getMessaging(app)
            getToken(messaging).then((currentToken) => {
                if (currentToken) {
                    console.log('CurrentToken', currentToken)

                    p.textContent = `${currentToken}`
                    bodyy.appendChild(p)

                } else {
                    console.log("cant get the token")
                    p.textContent = "cant get the token"
                    bodyy.appendChild(p)

                }
            })
        } else {
            console.log("do not have permission")
            p.textContent = "do not have permission"
            bodyy.appendChild(p)


        }
    })
}



requestPermission()